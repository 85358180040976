"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const aStarFinder_1 = require("@games/colorlines/src/aStarFinder");
const aStarFinder = new aStarFinder_1.AStarFinder();
function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}
class ColorlinesCtrl {
    constructor($scope, $filter, $location, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        var _a;
        this.$scope = $scope;
        this.$filter = $filter;
        this.$location = $location;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.prev = {
            board: undefined,
            score: undefined,
            nextColors: undefined,
            hint: undefined,
        };
        this.roundBallsCount = 3;
        this.boardSize = 9;
        this.isGameOver = false;
        this.nextColors = this.randomNext3Colors();
        this.timeRemaining = ColorlinesCtrl._formatTime(0);
        this.$path = new rxjs_1.Subject();
        this.moveDelay = 200;
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = 0;
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.level = 2;
        this.hint = [...Array(this.boardSize)].map(() => [...Array(this.boardSize).fill(null)]);
        this.path = [...Array(this.boardSize)].map(() => [...Array(this.boardSize).fill(null)]);
        this.hoveredTile = { x: 0, y: 0, isPath: false };
        this.score = 0;
        this.bestScore = JSON.parse(localStorage.getItem(`${this.constructor.name}_bestScore`) || 'null') || {
            '1': 0,
            '2': 0,
        };
        this.board = [...Array(this.boardSize)].map(() => [...Array(this.boardSize).fill(null)]);
        this.selectedTile = null;
        this.timerWork = false;
        const paramLevel = this.getParam('level');
        const data = JSON.parse(localStorage.getItem(`${this.constructor.name}_data`) || 'null');
        if (data) {
            if (paramLevel) {
                if (paramLevel != data.level) {
                    this.newGame();
                }
                else {
                    this.board = data.board;
                    this.score = data.score;
                    this.startWith = data.startWith;
                    this.timeRemaining = ColorlinesCtrl._formatTime(data.startWith);
                    this.prev = data.prev;
                    this.hint = data.hint;
                    this.level = data.level;
                }
            }
            else {
                this.board = data.board;
                this.score = data.score;
                this.startWith = data.startWith;
                this.timeRemaining = ColorlinesCtrl._formatTime(data.startWith);
                this.prev = data.prev;
                this.hint = data.hint;
                this.level = data.level;
            }
        }
        else {
            if (paramLevel) {
                if ([1, 2].indexOf(paramLevel) > -1) {
                    //@ts-ignore
                    this.level = paramLevel;
                }
            }
            this.newGame();
        }
        this.calcBoard();
        this.$scope.$watch(() => {
            let i = 0;
            for (let row of this.board) {
                for (let tile of row) {
                    if (tile)
                        i += 1;
                    if (i > 3)
                        break;
                }
                if (i > 3)
                    break;
            }
            if ((i > 3) || this.selectedTile) {
                return {
                    board: ng.copy(this.board),
                    score: this.score,
                    startWith: this.startWith,
                    prev: this.prev,
                    hint: this.hint,
                    level: this.level,
                };
            }
            else {
                return null;
            }
        }, (data) => {
            if (data) {
                if (this.selectedTile)
                    this.unselectTile(data.board[this.selectedTile.x][this.selectedTile.y]);
                localStorage.setItem(`${this.constructor.name}_data`, JSON.stringify(data));
                if (data.score > 0) {
                    const storedBestScore = JSON.parse(localStorage.getItem(`${this.constructor.name}_bestScore`) || 'null') || {
                        '1': 0,
                        '2': 0
                    };
                    storedBestScore[this.level.toString()] = Math.max(storedBestScore[this.level.toString()], data.score);
                    localStorage.setItem(`${this.constructor.name}_bestScore`, JSON.stringify(storedBestScore));
                    this.bestScore = storedBestScore;
                }
            }
            else {
                localStorage.removeItem(`${this.constructor.name}_data`);
            }
        }, true);
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            if (!value) {
                this.timeRemaining = ColorlinesCtrl._formatTime(this.startWith);
            }
            // console.log('_startTimer', value)
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            const e = new MouseEvent('click', { bubbles: true, cancelable: false });
            if ((this.isGameOver || !localStorage.getItem(`${this.constructor.name}_data`)) && (this.startWith == 0)) {
                // event.target?.dispatchEvent(e)
                window.location.href = `${currentTarget.href}?level=${this.level}`;
            }
            else {
                this._confirmNewGame((result) => {
                    if (result) {
                        localStorage.removeItem(`${this.constructor.name}_data`);
                        window.location.href = `${currentTarget.href}?level=${this.level}`;
                    }
                });
            }
        })).subscribe();
    }
    $onInit() {
        this.$path.pipe((0, operators_1.filter)((value) => (value.finalPath.length - 1) > 0), (0, operators_1.switchMap)((value) => {
            this.path = [...Array(this.boardSize)].map(() => [...Array(this.boardSize).fill(null)]);
            const len = this.moveDelay / (value.finalPath.length - 1);
            this.SoundService.play('move');
            return (0, rxjs_1.of)(...value.finalPath.reverse()).pipe(
            // skip(1),
            (0, operators_1.concatMap)((path, i) => {
                return (0, rxjs_1.timer)(i > 1 ? len : 0).pipe((0, operators_1.mapTo)(path));
            }), (0, operators_1.pairwise)(), (0, operators_1.map)((pair) => {
                let prev = pair[0];
                let cur = pair[1];
                this.$scope.$apply(() => {
                    if ((cur.x != value.x) || (cur.y != value.y)) {
                        if (this.path[cur.x][cur.y] == null) {
                            this.path[cur.x][cur.y] = {
                                x: cur.x,
                                y: cur.y,
                                classes: [value.colorClass, 'hint'],
                                colorClass: value.colorClass
                            };
                        }
                    }
                    if (this.path[prev.x][prev.y])
                        this.path[prev.x][prev.y] = null;
                });
                return cur;
            }));
        })).subscribe();
    }
    setLevel(level) {
        if (localStorage.getItem(`${this.constructor.name}_data`) || this.startWith != 0) {
            this._confirmNewGame((result) => {
                if (result) {
                    this.level = level;
                    this.$location.search('level', level);
                    this.newGame(true);
                }
            });
        }
        else {
            this.level = level;
            this.$location.search('level', level);
            this.newGame(true);
        }
    }
    newGame(force = false) {
        if ((this.startWith != 0) && !force) {
            this._confirmNewGame((result) => {
                if (result)
                    this.newGame(true);
            });
        }
        else {
            this._startTimer.next(null);
            this.prev = {
                board: undefined,
                nextColors: undefined,
                score: undefined,
                hint: undefined,
            };
            this.selectedTile = null;
            this.board = [...Array(this.boardSize)].map(() => [...Array(this.boardSize).fill(null)]);
            this.hint = [...Array(this.boardSize)].map(() => [...Array(this.boardSize).fill(null)]);
            this.isGameOver = false;
            this.startWith = 0;
            this.timeRemaining = '00:00';
            this.score = 0;
            this.nextColors = this.randomNext3Colors();
            this.addNext3Balls();
            this.nextColors = this.randomNext3Colors();
            this.prev.nextColors = this.nextColors;
            this.hint = this.nextHint();
        }
    }
    undo() {
        console.log('prev', ng.copy(this.prev));
        if (this.prev.board) {
            this.selectedTile = null;
            this.board = ng.copy(this.prev.board);
            this.nextColors = ng.copy(this.prev.nextColors);
            this.hint = ng.copy(this.prev.hint);
            this.score = this.prev.score;
            this.prev = {
                board: undefined,
                nextColors: this.nextColors,
                score: undefined,
                hint: this.hint,
            };
        }
    }
    unselectTile(tile) {
        if (tile) {
            tile.selected = false;
            tile.classes = tile.classes.filter((item) => item != 'selected');
        }
        return tile;
    }
    onTileClick(x, y) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isGameOver) {
                return;
            }
            if (this.board[x][y]) { // if there is ball on clicked tile: change selection
                console.log('is ball on clicked tile: change selection');
                this._startTimer.next(true);
                // remove selection from all balls:
                this.board.forEach((row) => row.forEach(this.unselectTile));
                if (!this.selectedTile || ((this.selectedTile.x != x) || (this.selectedTile.y != y)))
                    this.SoundService.play('click');
                this.selectedTile = Object.assign(Object.assign({}, this.board[x][y]), { classes: [...this.board[x][y].classes, 'selected'].filter(onlyUnique), selected: true, x: x, y: y });
                this.board[x][y] = this.selectedTile;
            }
            else { // if there is no ball on clicked tile
                if (this.selectedTile && this.hoveredTile.isPath) { // move, check if 5 in line
                    let path = aStarFinder.findPath(this.board, { x: this.selectedTile.x, y: this.selectedTile.y }, { x: x, y: y }, {
                        x: this.boardSize, y: this.boardSize
                    });
                    if (path.finalPath)
                        this.$path.next({
                            finalPath: path.finalPath,
                            colorClass: this.selectedTile.colorClass,
                            x: x,
                            y: y,
                        });
                    this.prev.board = ng.copy(this.board);
                    this.unselectTile(this.prev.board[this.selectedTile.x][this.selectedTile.y]);
                    this.prev.score = this.score;
                    this.prev.nextColors = ng.copy(this.nextColors);
                    const selectedBallColor = this.selectedTile.colorClass;
                    this.board[this.selectedTile.x][this.selectedTile.y] = null;
                    this.selectedTile = null;
                    yield this.$timeout(() => {
                        console.log('set');
                        this.hint[x][y] = null;
                        this.board[x][y] = {
                            colorClass: selectedBallColor,
                            selected: false,
                            classes: ['ball', selectedBallColor],
                            x: x,
                            y: y
                        };
                    }, this.moveDelay);
                    yield this.$timeout(() => {
                        const stepScore = this.checkFor5({ x: x, y: y }, selectedBallColor);
                        if (stepScore != 0) {
                            this.score += stepScore;
                        }
                        else {
                            if (!this.addNext3Balls()) {
                                this.endGame();
                            }
                            this.nextColors = this.randomNext3Colors();
                            this.hint = this.nextHint();
                        }
                    }, 200);
                }
                else if (!this.hoveredTile.isPath) {
                    this.SoundService.play('noway');
                }
            }
        });
    }
    onTileHover(x, y) {
        if (this.selectedTile) {
            if (!this.board[x][y]) {
                const to = { x: x, y: y };
                const from = { x: this.selectedTile.x, y: this.selectedTile.y };
                let path = aStarFinder.findPath(this.board, from, to, { x: this.boardSize, y: this.boardSize });
                // uncomment if you want to visualize a-star searching algorithm:
                // paintPath(path as any, tileNodes);
                this.hoveredTile = { x: to.x, y: to.y, isPath: path.success };
            }
        }
    }
    nextHint() {
        let hint = [...Array(this.boardSize)].map(() => [...Array(this.boardSize).fill(null)]);
        if (this.level < 2) {
            for (let i = 0; i < this.freeHintCount();) {
                if (this.isBoardFull()) {
                    return hint; // loss
                }
                // try to find next free tile in board
                const x = randomInt(0, 8);
                const y = randomInt(0, 8);
                if ((this.board[x][y] == null) && (hint[x][y] == null)) {
                    hint[x][y] = {
                        colorClass: this.nextColors[i],
                        classes: [this.nextColors[i], 'hint'],
                        x: x,
                        y: y,
                    };
                    i++;
                }
            }
        }
        this.prev.hint = hint;
        return hint;
    }
    checkFor5(movedPos, movedColor) {
        // todo: fix vertical, horizontal
        function checkInDirection(dirX, dirY) {
            let line = [movedPos];
            let iDir = 1; // go in specified by dirX and dirY or direction (1) or go backwards (-1)
            let i = 1;
            for (; true; i += iDir) { // keep checking for the same color in that direction
                let x = i * dirX + movedPos.x; // posX
                let y = i * dirY + movedPos.y; // posY
                // 1. if position is not inside the board:
                if ((x >= 0 && y >= 0 && x < this.boardSize && y < this.boardSize) == false) {
                    if (iDir == 1) {
                        i = 0; // will be -1 after iteration
                        iDir = -1;
                        continue;
                    }
                    else {
                        if (line.length >= 5) {
                            return line;
                        }
                        else {
                            return [];
                        }
                    }
                }
                // 2. if next color is the same, add it to line
                if (this.board[x][y] && (this.board[x][y].colorClass == movedColor)) {
                    line.push({ x, y });
                }
                else { // no more balls of the same color in this direction, change direction
                    if (iDir == 1) {
                        i = 0; // will be -1 after iteration
                        iDir = -1;
                    }
                    else {
                        if (line.length >= 5) {
                            return line;
                        }
                        else {
                            return [];
                        }
                    }
                }
            }
        }
        const checkInDirectionClass = checkInDirection.bind(this);
        let diagonal1 = checkInDirectionClass(-1, 1);
        let vertical = checkInDirectionClass(0, 1);
        let diagonal2 = checkInDirectionClass(1, 1);
        let horizontal = checkInDirectionClass(1, 0);
        let ballsToRemove = diagonal1.concat(vertical).concat(diagonal2).concat(horizontal);
        if (ballsToRemove.length > 0) {
            let removedCount = 0;
            ballsToRemove.forEach((ball) => {
                if (this.board[ball.x][ball.y] == null) {
                    return; // skip, it's a duplicate
                }
                this.board[ball.x][ball.y] = null;
                // tileNodes[ball.x][ball.y].innerHTML = '';
                removedCount++;
            });
            if (removedCount)
                this.SoundService.play('fire');
            return removedCount; // next turn
        }
        else {
            return 0; // no next turn
        }
    }
    randomNext3Colors() {
        return [...Array(this.roundBallsCount).keys()].map(() => `color${randomInt(1, 7)}`);
    }
    calcBoard() {
        let score = 0;
        this.board.forEach((row) => row.forEach((tile) => {
            if (tile)
                score += this.checkFor5({ x: tile.x, y: tile.y }, tile.colorClass);
        }));
        this.score += score;
    }
    isBoardFull() {
        return this.board.every((row) => {
            return row.every((tile) => {
                return tile != null;
            });
        });
    }
    freeHintCount() {
        let i = 0;
        this.board.map((row) => {
            return row.map((tile) => i += (tile == null ? 1 : 0));
        });
        return Math.min(i, this.roundBallsCount);
    }
    isBoardClean() {
        return this.board.every((row) => {
            return row.every((tile) => {
                return tile == null;
            });
        });
    }
    addNext3Balls() {
        const newlyAdded = [];
        const hintItems = [];
        if (this.hint) {
            this.hint.forEach((row) => row.forEach((item) => {
                if (item) {
                    hintItems.push(item);
                }
            }));
        }
        for (let i = 0; i < this.roundBallsCount;) {
            if (this.isBoardFull()) {
                return false; // loss
            }
            let colorClass;
            let x;
            let y;
            // try to find next free tile in board
            if (hintItems.length) {
                const hint = hintItems[i];
                if (hint) {
                    colorClass = hint.colorClass;
                    x = hint.x;
                    y = hint.y;
                }
                else {
                    // if selectedTile moved to hint
                    colorClass = this.nextColors[i];
                    x = randomInt(0, 8);
                    y = randomInt(0, 8);
                }
            }
            else {
                colorClass = this.nextColors[i];
                x = randomInt(0, 8);
                y = randomInt(0, 8);
            }
            if (this.board[x][y] == null) {
                const tile = {
                    colorClass: colorClass,
                    classes: [colorClass, 'ball'],
                    x: x,
                    y: y,
                    selected: false
                };
                this.board[x][y] = tile;
                newlyAdded.push(tile);
                i++;
            }
        }
        this.SoundService.play('new');
        // for every nextBall: check for 5 in a row after adding it
        newlyAdded.forEach((tile) => {
            this.score += this.checkFor5({ x: tile.x, y: tile.y }, tile.colorClass);
        });
        return !this.isBoardFull();
    }
    pauseGame() {
        if (!this.isGameOver && this.ConfigService.cookieSettings.show_timer) {
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                this._resume.next('visible');
            });
        }
    }
    debug() {
        (0, rxjs_1.interval)(300).pipe((0, operators_1.tap)(() => {
            this.$scope.$apply(() => {
                this.newGame();
                let totalUnique = 0;
                this.board.map((row, x) => row.map((item, y) => {
                    if (item) {
                        if (!this.hint[x][y])
                            totalUnique += 1;
                    }
                }));
                this.hint.map((row, x) => row.map((item, y) => {
                    if (item) {
                        if (!this.board[x][y])
                            totalUnique += 1;
                    }
                }));
                console.log(totalUnique);
            });
        }), (0, operators_1.take)(100)).subscribe();
    }
    endGame() {
        this._stopTimer.next(null);
        this.isGameOver = true;
        this.selectedTile = null;
        this.SoundService.play('lose');
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./end_game.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                show_timer: this.ConfigService.cookieSettings.show_timer,
                timeRemaining: this.timeRemaining,
                score: this.score,
                levelBestScore: this.bestScore[this.level.toString()],
                cookieSettings: this.ConfigService.cookieSettings
            }
        }).then((result) => {
            localStorage.removeItem(`${this.constructor.name}_data`);
            if (result == 'newGame') {
                this.newGame(true);
            }
        });
    }
    _confirmNewGame(callback) {
        const timerWork = this.timerWork;
        this._stopTimer.next(null);
        return this.ModalServiceFactory.open({
            id: 'colorlines_new_game',
            component: "confirm-comp",
            scope: this.$scope,
            extraContext: {
                settings: {}
            }
        }).then((result) => {
            if (result) {
                callback ? callback(result) : null;
            }
            else {
                if (timerWork) {
                    this._startTimer.next(null);
                    this._startTimer.next(true);
                }
                throw { error: 'cancel' };
            }
        });
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            const sec = i + startWith;
            this.$timeout(() => {
                this.timerWork = true;
                this.startWith = sec;
                this.timeRemaining = ColorlinesCtrl._formatTime(sec);
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    getParam(key) {
        if (key in this.$location.search()) {
            return parseInt(this.$location.search()[key]) || null;
        }
        return null;
    }
    static _formatTime(sec) {
        if (sec >= 3600) {
            return new Date(sec * 1000).toISOString().substr(11, 8);
        }
        return new Date(sec * 1000).toISOString().substr(14, 5);
    }
}
ColorlinesCtrl.$inject = ['$scope', '$filter', '$location', '$timeout', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameColorlines', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: ColorlinesCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'SoundServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, SoundServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('colorlines/');
        SoundServiceProvider.setSound({
            'move': require('./sounds/move.mp3').default,
            'click': require('./sounds/click.mp3').default,
            'fire': require('./sounds/fire.mp3').default,
            'new': require('./sounds/new.mp3').default,
            'noway': require('./sounds/noway.mp3').default,
            'lose': require('./sounds/lose.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            show_timer: true,
            sound_effects: false,
        });
    }]);
